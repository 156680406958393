/* Custom styles for the TimeRange component */
.time-range-container {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  
  .time-picker-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .time-picker-label {
    font-size: 0.875rem; /* Small font size */
    color: #6b7280; /* Gray color */
    margin-bottom: 0.25rem; /* Space below the label */
  }
  
  .custom-time-picker {
    border: 1px solid #d1d5db; /* Light gray border */
    border-radius: 0.375rem; /* Rounded corners */
    padding: 0.5rem; /* Padding inside the input */
    font-size: 1rem; /* Font size */
    color: #374151; /* Text color */
  }
  
  .custom-time-picker input {
    width: 100%; /* Full width */
    border: none; /* Remove default border */
    outline: none; /* Remove default outline */
  }
  
  .custom-time-picker .react-time-picker__wrapper {
    border: none; /* Remove default border */
  }
  
  .custom-time-picker .react-time-picker__inputGroup__input {
    padding: 0.25rem; /* Padding inside the input */
    font-size: 1rem; /* Font size */
    color: #374151; /* Text color */
  }
  
  .custom-time-picker .react-time-picker__inputGroup__divider {
    color: #9ca3af; /* Divider color */
  }
  
  .custom-time-picker .react-time-picker__clock-button {
    display: none; /* Hide the clock button */
  }