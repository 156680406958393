@font-face {
  font-family: "Swear";
  font-weight: 400;
  src: url(../assets/fonts/Swear-Display-Italic.otf) format("opentype");
}
@font-face {
  font-family: "Ppneuemontreal";
  font-weight: 00;
  src: url(../assets/fonts/ppneuemontreal-book.otf) format("opentype");
}

@font-face {
  font-family: "Ppneuemontreal";
  font-weight: 500;
  src: url(../assets/fonts/ppneuemontreal-medium.otf) format("opentype");
}

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  :root {
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}


html,
body {
  background-color: white;
  padding: 0;
  margin: 0;
  font-family: "Ppneuemontreal", -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif, "Work Sans";
  color: #111;
}

.shadow-inner {
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.1),
    inset 0 -6px 30px rgba(0, 0, 0, 0.1);
}

.primary-gradient {
  background: linear-gradient(90deg, #279a86 0%, #238a78 100%);
}

.center-vertical {
  top: 50%;
  transform: translateY(-50%);
}

.ellipsis-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden
}


@keyframes rotate-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@mixin rotate($duration: 1.5s, $timing-function: linear, $iteration-count: infinite) {
  animation: rotate-animation $duration $timing-function $iteration-count;
}

.spin {
  @include rotate();
}
